import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot } from "react-dom/client";
import EditorComponent from "../EditorComponent";
import {BlockNoteEditor, BlockNoteSchema, defaultBlockSpecs} from "@blocknote/core";
import {CheckListItem} from "../CheckListItemBlockContent";

export default class extends Controller {
  static targets = [ "reactRoot", "savingSpinner" ];
  static values = {
    id: String,
    editable: Boolean,
    data: Object,
  }

  connect() {
    this.initializeEditor();
    this.props = {
      id: this.idValue,
      editable: this.editableValue,
      data: this.dataValue,
      onChange: this.debouncedOnChange.bind(this),
      editor: this.editor
    }
    this.root = createRoot(this.reactRootTarget);
    this.root.render(<EditorComponent {...this.props} />);
  }

  debouncedOnChange(editor) {
    clearTimeout(this.changeTimeout);
    this.changeTimeout = setTimeout(() => this.onChange(editor), 500);
  }

  onChange(editor){
    let outputData = {blocks: editor.document};

    if (!this.editor.isEditable) {
      let checkChanged = false;

      outputData.blocks.forEach(block => {
        if (block.type === "checkListItem") {
          if (block.props.checked !== this.dataValue.blocks.find(b => b.id === block.id).props.checked) {
            checkChanged = true;
          }
        }
      });

      if (!checkChanged) {
        return;
      }
    }

    this.dataValue = outputData;
    this.savingSpinnerTarget.classList.remove('hidden');

    fetch("/notes", {
      method: "POST",
      body: JSON.stringify({
        id: `${this.idValue}`,
        content: outputData
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(r => this.savingSpinnerTarget.classList.add('hidden'));
  }
  disconnect(){
    this.root.unmount();
  }

  edit() {
    this.editableValue = !this.editableValue;
    this.props = {
      id: this.idValue,
      editable: this.editableValue,
      data: this.dataValue,
      onChange: this.debouncedOnChange.bind(this),
      editor: this.editor
    }
    this.root.render(<EditorComponent {...this.props} />);
  }

  initializeEditor() {
    let blocks = this.dataValue;

    this.editor = BlockNoteEditor.create({
      initialContent: blocks.blocks,
      schema: BlockNoteSchema.create({
        blockSpecs: {
          ...defaultBlockSpecs,
          checkListItem: CheckListItem,
          video: undefined,
          audio: undefined,
          image: undefined,
          file: undefined,
        },
      })
    });
  }
}