import React, {useEffect, useMemo} from "react";
import {
  useCreateBlockNote,
  SuggestionMenuController,
  SideMenuController,
  SideMenu,
  DragHandleMenu, RemoveBlockItem, AddBlockButton, DragHandleButton
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";

function CustomSlashMenu(
  props
) {
  return (
    <ul className={"dropdown-content menu p-2 drop-shadow-lg bg-base-300 rounded-box w-54"}>
      {props.items.map((item, index) => (
        <li
          key={index}
          onClick={() => {
            props.onItemClick?.(item);
          }}>
          <a
            className={`${
              props.selectedIndex === index ? "focus" : ""
            }`}>{item.icon}{item.title}</a>
        </li>
      ))}
    </ul>
  );
}

export default function EditorComponent(props) {
  return <BlockNoteView editor={props.editor}
                        slashMenu={false}
                        onChange={props.onChange}
                        editable={props.editable}
                        className={props.editable ? "border border-secondary rounded-box ps-14" : ""}
                        sideMenu={false}
  >
    <SuggestionMenuController
      triggerCharacter={"/"}
      suggestionMenuComponent={CustomSlashMenu}
    />
    <SideMenuController
      sideMenu={(props) => (
        <SideMenu
          {...props}
        >
          <AddBlockButton {...props} />
          <DragHandleButton {...props} dragHandleMenu={() => {}} />
        </SideMenu>
      )}
    />
  </BlockNoteView>;
}
