import { InputRule } from "@tiptap/core";
import {
  createBlockSpecFromStronglyTypedTiptapNode,
  createDefaultBlockDOMOutputSpec, createStronglyTypedTiptapNode, defaultProps,
  getCurrentBlockContentType
} from "@blocknote/core";
import {handleEnter} from "../../node_modules/@blocknote/core/src/blocks/ListItemBlockContent/ListItemKeyboardShortcuts";

export const checkListItemPropSchema = {
  ...defaultProps,
  checked: {
    default: false,
  },
};

const checkListItemBlockContent = createStronglyTypedTiptapNode({
  name: "checkListItem",
  content: "inline*",
  group: "blockContent",
  addAttributes() {
    return {
      checked: {
        default: false,
        parseHTML: (element) =>
          element.getAttribute("data-checked") === "true" || undefined,
        renderHTML: (attributes) => {
          return attributes.checked
            ? {
              "data-checked": attributes.checked.toString(),
            }
            : {};
        },
      },
    };
  },

  addInputRules() {
    return [
      new InputRule({
        find: new RegExp(`\\[\\s*\\]\\s$`),
        handler: ({ state, chain, range }) => {
          if (getCurrentBlockContentType(this.editor) !== "inline*") {
            return;
          }

          chain()
            .BNUpdateBlock(state.selection.from, {
              type: "checkListItem",
              props: {
                checked: false,
              },
            })
            .deleteRange({ from: range.from, to: range.to });
        },
      }),
      new InputRule({
        find: new RegExp(`\\[[Xx]\\]\\s$`),
        handler: ({ state, chain, range }) => {
          if (getCurrentBlockContentType(this.editor) !== "inline*") {
            return;
          }

          chain()
            .BNUpdateBlock(state.selection.from, {
              type: "checkListItem",
              props: {
                checked: true,
              },
            })
            .deleteRange({ from: range.from, to: range.to });
        },
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => handleEnter(this.editor, this.block),
      "Mod-Shift-9": () => {
        if (getCurrentBlockContentType(this.editor) !== "inline*") {
          return true;
        }

        return this.editor.commands.BNUpdateBlock(
          this.editor.state.selection.anchor,
          {
            type: "checkListItem",
            props: {},
          }
        );
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-content-type=" + this.name + "]",
      },
      {
        tag: "input",
        getAttrs: (element) => {
          if (typeof element === "string") {
            return false;
          }

          if (element.type === "checkbox") {
            return { checked: element.checked };
          }

          return false;
        },
        node: "checkListItem",
      },
      {
        tag: "li",
        getAttrs: (element) => {
          if (typeof element === "string") {
            return false;
          }

          const parent = element.parentElement;

          if (parent === null) {
            return false;
          }

          if (
            parent.tagName === "UL" ||
            (parent.tagName === "DIV" && parent.parentElement.tagName === "UL")
          ) {
            const checkbox =
              element.querySelector("input[type=checkbox]") || null;

            if (checkbox === null) {
              return false;
            }

            return { checked: checkbox.checked };
          }

          return false;
        },
        node: "checkListItem",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = node.attrs.checked;
    if (node.attrs.checked) {
      checkbox.setAttribute("checked", "");
    }

    const { dom, contentDOM } = createDefaultBlockDOMOutputSpec(
      this.name,
      "p",
      {
        ...(this.options.domAttributes?.blockContent || {}),
        ...HTMLAttributes,
      },
      this.options.domAttributes?.inlineContent || {}
    );

    dom.insertBefore(checkbox, contentDOM);

    return { dom, contentDOM };
  },

  addNodeView() {
    return ({ node, getPos, editor, HTMLAttributes }) => {
      const wrapper = document.createElement("div");
      const checkboxWrapper = document.createElement("div");
      checkboxWrapper.contentEditable = "false";

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.checked = node.attrs.checked;
      if (node.attrs.checked) {
        checkbox.setAttribute("checked", "");
      }

      const changeHandler = () => {
        if (typeof getPos !== "boolean") {
          this.editor.commands.BNUpdateBlock(getPos(), {
            type: "checkListItem",
            props: {
              checked: checkbox.checked,
            },
          });
        }
      };
      checkbox.addEventListener("change", changeHandler);

      const { dom, contentDOM } = createDefaultBlockDOMOutputSpec(
        this.name,
        "p",
        {
          ...(this.options.domAttributes?.blockContent || {}),
          ...HTMLAttributes,
        },
        this.options.domAttributes?.inlineContent || {}
      );

      if (typeof getPos !== "boolean") {
        const blockID = this.editor.state.doc.resolve(getPos()).node().attrs.id;
        const label = "label-" + blockID;
        checkbox.setAttribute("aria-labelledby", label);
        contentDOM.id = label;
      }

      dom.removeChild(contentDOM);
      dom.appendChild(wrapper);
      wrapper.appendChild(checkboxWrapper);
      wrapper.appendChild(contentDOM);
      checkboxWrapper.appendChild(checkbox);

      return {
        dom,
        contentDOM,
        destroy: () => {
          checkbox.removeEventListener("change", changeHandler);
        },
      };
    };
  },
});

export const CheckListItem = createBlockSpecFromStronglyTypedTiptapNode(
  checkListItemBlockContent,
  checkListItemPropSchema
);